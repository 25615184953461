<template>
  <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container overflow-auto" style="background-color: rgba(64, 105, 154, .6); -webkit-backdrop-filter: blur(15px); backdrop-filter: blur(15px);">
              <div class="flex justify-between">
                <div class="flex items-center flex-wrap xl:flex-no-wrap"> 
                  <div class="hidden xl:inline">
                    <slot name="logo" class="logo">
                      <!--<svg class="text-white stroke-current p-2 " xmlns="http://www.w3.org/2000/svg" width="98.048" height="92.446" viewBox="0 0 98.048 92.446">
                        <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(-719.969 -22.562)">
                          <path id="Pfad_7" data-name="Pfad 7" d="M795.317,111.377H729.768V56.322l36.206-24.495,36.209,24.495v55.055" transform="translate(3.018 2.632)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
                          <path id="Pfad_8" data-name="Pfad 8" d="M720.541,57.616l48.45-33.835,48.453,33.835" transform="translate(0)" fill="none"  stroke-miterlimit="10" stroke-width="2"/>
                          <g id="Gruppe_5" data-name="Gruppe 5" transform="translate(754.005 58.606)">
                          <g id="Gruppe_4" data-name="Gruppe 4" transform="translate(11.945 23.652)">
                              <path id="Pfad_9" data-name="Pfad 9" d="M760.839,70.952a3.042,3.042,0,1,1-3.042-3.108A3.075,3.075,0,0,1,760.839,70.952Z" transform="translate(-754.757 -67.844)" fill="none"  stroke-linejoin="round" stroke-width="2"/>
                              <line id="Linie_7" data-name="Linie 7" y1="4.517" transform="translate(3.041 6.215)" fill="none"   stroke-linejoin="round" stroke-width="2"/>
                          </g>
                          <path id="Pfad_10" data-name="Pfad 10" d="M775.729,62.1H745.756V86.528h29.973V62.1Z" transform="translate(-745.756 -46.072)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2"/>
                          <path id="Pfad_11" data-name="Pfad 11" d="M749.031,66.047V60.132c0-5.923,4.764-10.11,10.64-10.11h0c5.875,0,10.64,4.187,10.64,10.11v1.589" transform="translate(-744.685 -50.022)" fill="none" stroke-miterlimit="10" stroke-width="2"/>
                        </g>
                      </g>
                      </svg>-->
                    </slot>
                  </div>
                  <div class="modal-header text-white xl:ml-4 mt-3 text-3xl text-left">
                    <slot name="header">
                      default header
                    </slot>
                  </div>
                </div>
                 <button class="modal-default-button focus:outline-none" @click="$emit('close')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60.121" height="60.121" viewBox="0 0 60.121 60.121">
                      <g id="Gruppe_117" data-name="Gruppe 117" transform="translate(1.061 1.061)">
                        <line id="Linie_15" data-name="Linie 15" x2="58" y2="58" fill="none" stroke="#fff" stroke-width="3"/>
                        <line id="Linie_16" data-name="Linie 16" x2="58" y2="58" transform="translate(58) rotate(90)" fill="none" stroke="#fff" stroke-width="3"/>
                      </g>
                    </svg>
                  </button>
              </div>

              <div class="modal-body text-white text-left w-full md:w-2/3">
                <slot name="body">
                  default body
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
</template>

<script>
export default {
methods: {
    close() {  this.$emit('close') }
  }
}
</script>

<style>
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>