<template>
<div>
    <!--<div class="partner bg-mainblue py-10 text-white mt-32">
        <span class="text-3xl">Partner und Zertifikate</span><br />
        <span class="text-lg">Geprüft und unterstützt durch:</span>
    </div>
    <div class="siegel py-6 text-mainblue flex justify-center flex-wrap">
        --><!--<span class="text-3xl flex -ml-10"><img :src="require('../assets/img/tuev1.png')" /> <img :src="require('../assets/img/tuev.png')" /></span><br />
        --><!--<span class="text-base w-full mt-8"><strong>PDF Download</strong> mit allen Zertifikaten und Partnern</span>
    </div>-->
    <div class="thefooter bg-footerblack pt-6 text-white block md:hidden mt-32">
        <span class="text-base"><span @click="goimpressum()" class="mr-8">Impressum</span> <span @click="godatenschutz()">Datenschutz</span></span>
    </div>
    <div class="thefooter bg-footerblack py-6 text-white md:mt-32">
        <span class="text-base">Alle Rechte vorbehalten {{new Date().getFullYear()}}. Ein Produkt von HS-Sicherheitssysteme. Erstellt von <a class="underline" href="https://www.jacoedo.de" target="_blank">jaco/edo GmbH</a></span>
    </div>
</div>

</template>

<script>
export default {
methods: {
        goimpressum: function() {
            window.open('https://www.hs-sicherheitssysteme.com/impressum/','_blank')
        },
        godatenschutz: function() {
            window.open('https://www.hs-sicherheitssysteme.com/datenschutz/','_blank')
        },
    }
}
</script>

<style>

</style>