<template>
  <div>
   <!-- <div class="bg-gray-500 h-2"></div>-->
    <div class="flex space-x-2">
      <div class="flex space-x-2" v-for="s in steps" :key="s">
        <!--<div
          v-if="step >= s"
          class="bg-red-500 -mt-2 h-2"
          :class="{ 'w-1/2': step == s, 'w-full': step < s }"
        ></div>-->
        <div v-if="question == page"
          class="-mt-4 rounded-full h-2 w-2 transition"
          :class="{ 'bg-mainblue bigger': s <= step, 'bg-mainbluelight smallagain': step < s }"
        ></div>
        <div v-if="question < page"
          class="-mt-4 rounded-full h-2 w-2 bg-mainblue transition smallagain"
        ></div>
        <div v-if="question > page"
          class="-mt-4 rounded-full h-2 w-2 bg-mainbluelight transition"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["steps", "step", "inactive", "page", "question"]
};
</script>